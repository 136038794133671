import {
  BOLD_ICONS,
  alertAloneFull,
  alertFull,
  infoFull,
} from '@emobg/vue-base';

export const NOTIFICATION_DEFAULT_PRIORITY = {
  exceptionalPriority: -4,
  maxPriority: -3,
  critical: -2,
  danger: -1,
  success: 1,
  warning: 2,
  info: 3,
};

export const NOTIFICATION_ICONS = {
  danger: alertFull,
  success: BOLD_ICONS.check,
  warning: alertAloneFull,
  info: infoFull,
};
