<template>
  <div class="Logo">
    <ui-image
      :alt="$t('common.logo')"
      :src="logoUrl"
      :width="LOGO_SIZE.width"
      :height="LOGO_SIZE.height"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { isValueIncluded } from '@emobg/web-utils';
import { ASSETS_CATEGORY, LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { LOGO_SIZE } from '@/components/Logo/Logo.const';

export default {
  name: 'Logo',
  props: {
    variant: {
      type: String,
      default: LOGOS_KEYNAMES.PRIMARY_LOGO,
    },
  },
  setup(props) {
    const logoUrl = computed(() => {
      const logoKeyName = isValueIncluded([LOGOS_KEYNAMES.PRIMARY_LOGO, LOGOS_KEYNAMES.CONTRAST_LOGO], props.variant)
        ? props.variant
        : LOGOS_KEYNAMES.PRIMARY_LOGO;
      return getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.LOGO, logoKeyName);
    });
    return { logoUrl, LOGO_SIZE };
  },
};
</script>
